/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { FaTwitter, FaEnvelopeOpen, FaMedium } from 'react-icons/fa';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/title';
import Avatar from '../components/avatar';
import Card from '../components/card';

const pojectsQuery = graphql`
  query {
    directLinkGeneratorImg: file(relativePath: { eq: "projects/directlinkgenerator.com.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dispatchAdsImg: file(relativePath: { eq: "projects/dispatchads.com.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goMarketImg: file(relativePath: { eq: "projects/gomarket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ledexImg: file(relativePath: { eq: "projects/ledex.ec.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stegamorseImg: file(relativePath: { eq: "projects/stegamorse.com.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    xeroMediaImg: file(relativePath: { eq: "projects/xeromedia.com.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
const IndexPage = () => {
  const projectsData = useStaticQuery(pojectsQuery);

  const projects = [
    {
      image: projectsData.stegamorseImg.childImageSharp.fluid,
      href: 'https://stegamorse.com',
      title: 'STEGAMORSE',
      tags: ['Steganography', 'React', 'NodeJs', 'Python', 'AWS'],
      content: 'Create a tool for hiding text messages in audio files.',
    },
    {
      image: projectsData.goMarketImg.childImageSharp.fluid,
      href: null,
      title: 'GoMarket',
      tags: ['React'],
      content: 'Create and animate the menu for their digital signage displays.',
    },
    {
      image: projectsData.ledexImg.childImageSharp.fluid,
      href: 'https://ledex.ec',
      title: 'LEDEX',
      tags: ['Gatsby', 'React', 'GraphQL', 'Wordpress', 'MySQL'],
      content:
        'Create a website capable of searching and displaying information for hundreds of products, backend in Wordpress and frontend in React using Gatsby.',
    },
    {
      image: projectsData.xeroMediaImg.childImageSharp.fluid,
      href: 'https://xeromedia.com',
      title: 'XeRo Media',
      tags: ['fullPage.js', 'PHP', 'Stripe'],
      content: 'Redesign their website to engage new prospects.',
    },
    {
      image: projectsData.directLinkGeneratorImg.childImageSharp.fluid,
      href: 'https://directlinkgenerator.com',
      title: 'Direct Link Generator',
      tags: ['JS', 'PHP', 'Google API'],
      content: 'Create a tool to get the direct URL for any public Google Drive link.',
    },
    {
      image: projectsData.dispatchAdsImg.childImageSharp.fluid,
      href: null,
      title: 'Dispatch Ads',
      tags: ['Socket.IO', 'Python', 'NodeJS', 'AWS'],
      content: 'Create interactive content for digital signage displays.',
    },
  ];
  const aboutData = [
    {
      id: 'wro',
      year: 2019,
      content: 'World Robot Olympiad 2019 Ecuador - Head judge for WeDo',
    },
    {
      id: 'mis',
      year: 2019,
      content:
        "Obtained a Master's Degree in Information Security from the International University of La Rioja",
    },
    {
      id: 'tru',
      year: 2018,
      content:
        'Achieved Top Rated rating in <a class="font-bold hover:text-yellow" href="https://www.upwork.com/o/profiles/users/_~019c7900e4f56d86ed/" target="_blank" rel="noopener noreferrer">UpWork</a>',
    },
    {
      id: 'lfcse',
      year: 2017,
      content:
        '<a class="font-bold hover:text-yellow" href="https://portal.linuxfoundation.org/certification/verify-certifications" target="_blank" rel="noopener noreferrer">Linux Foundation Certified Systems Engineer​</a> - LFCE-1700-0402-0200',
    },
    {
      id: 'freelancer',
      year: 2016,
      content:
        'Started freelancing fulltime in <a class="font-bold hover:text-yellow" href="https://www.upwork.com/o/profiles/users/_~019c7900e4f56d86ed/" target="_blank" rel="noopener noreferrer">UpWork</a>',
    },
  ];
  return (
    <Layout mainClassName="">
      <SEO title="Andrés Checa | Web Developer" />
      <div className="text-white text-lg font-light">
        <section className="p-6 sm:py-12">
          <div className="container mx-auto flex flex-col justify-center items-center">
            <div className="w-48 h-48 my-6">
              <Avatar small={false} />
            </div>
            <h3 className="text-2xl sm:text-3xl mb-6">Hi! I'm Andrés Checa</h3>
            <p className="mb-3 md:max-w-xl md:text-center">
              I have been helping people with their businesses using web technologies for the last
              10 years ranging from large companies to personal side projects.
            </p>
          </div>
        </section>
        <section className="p-6 sm:py-12 bg-blue-800">
          <div className="container mx-auto">
            <Title id="about-me">About Me</Title>
            <div className="max-w-5xl mx-auto">
              {aboutData.map((about, index) => (
                <div key={about.id}>
                  <div className="flex items-baseline mb-3 sm:items-center">
                    <div className="hidden bg-gray-100 rounded-full w-5 h-5 ml-1 flex flex-shrink-0 sm:block" />
                    <div className="text-gray-100 flex-shrink-0 mr-3 sm:mx-6 w-12 text-center">
                      {about.year}
                    </div>
                    <div
                      className="text-gray-100 flex-grow-1"
                      dangerouslySetInnerHTML={{ __html: about.content }}
                    />
                  </div>
                  {index < aboutData.length - 1 && (
                    <div className="hidden bg-gray-100 py-10 w-1 -mb-5 -mt-8 ml-3 relative sm:block" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="p-6 sm:py-12 bg-gray-50">
          <div className="container mx-auto">
            <Title id="projects" alternate>
              Projects
            </Title>
            <p className="mb-3 text-gray">These are a few projects I've been part of:</p>
            <div className="flex flex-wrap flex-col items-start justify-around sm:flex-row">
              {projects.map(project => (
                <Card
                  key={project.title}
                  image={project.image}
                  href={project.href}
                  title={project.title}
                  tags={project.tags}
                >
                  {project.content}
                </Card>
              ))}
            </div>
          </div>
        </section>
        <section className="p-6 sm:py-12 bg-blue-800">
          <div className="container mx-auto">
            <Title id="contact-me">Contact Me</Title>
            <p className="mb-3">
              Use any of the links below to get in touch with me (warning! mailto link ahead).
            </p>
            <div>
              <a
                href="mailto:andres@checa.ec"
                className="flex items-center text-white font-bold hover:text-yellow"
              >
                <FaEnvelopeOpen />
                <span className="ml-3">andres@checa.ec</span>
              </a>
              <a
                href="https://www.twitter.com/andres_checa"
                target="_blank"
                rel="noreferrer noopener"
                className="flex items-center text-white font-bold hover:text-yellow"
              >
                <FaTwitter />
                <span className="ml-3">@andres_checa</span>
              </a>
              <a
                href="https://medium.com/@acheca"
                target="_blank"
                rel="noreferrer noopener"
                className="flex items-center text-white font-bold hover:text-yellow"
              >
                <FaMedium />
                <span className="ml-3">@acheca</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;
