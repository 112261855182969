import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const avatarQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    image: file(relativePath: { eq: "andres-checa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 128, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Avatar = ({ small }) => {
  const avatarData = useStaticQuery(avatarQuery);
  return (
    <div className={`${small ? 'w-16 h-16' : 'w-full h-full'} rounded-full shadow-outline`}>
      <Img
        fluid={avatarData.image.childImageSharp.fluid}
        alt={avatarData.site.siteMetadata.title}
        className="rounded-full w-full h-full"
      />
    </div>
  );
};

Avatar.propTypes = {
  small: PropTypes.bool,
};

Avatar.defaultProps = {
  small: true,
};

export default Avatar;
