/* eslint-disable react/forbid-prop-types */
import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Card = ({ children, image, href, tags, title }) => (
  <div className="flex-grow max-w-sm rounded overflow-hidden shadow-lg bg-white text-gray-500 mx-4 my-6">
    <a href={href} target="_blank" rel="noreferrer noopener">
      <Img className="w-full" fluid={image} alt={title} />
    </a>
    <div className="px-6 py-4">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className={`font-bold text-xl mb-2 ${href ? 'hover:text-yellow' : ''}`}
      >
        {title}
      </a>
      <p className="text-gray-700 text-base">{children}</p>
    </div>
    <div className="px-6 pb-4 text-gray">
      {tags.map((tag, index) => (
        <span
          key={tag}
          className={`inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mb-2 ${
            index < tags.length - 1 ? 'mr-2' : ''
          }`}
        >
          {tag}
        </span>
      ))}
    </div>
  </div>
);

Card.propTypes = {
  children: PropTypes.any.isRequired,
  image: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default Card;
